import React from 'react'

import Layout from '../../components/Layout'
import SEO from "../../components/Seo"
import TrustedBlock from '../../components/TrustedBlock'

import Form from '../../components/Pages/GetStarted/Form'
import AllInOne from '../../components/Pages/GetStarted/AllInOne'



import './contact.scss'

export default ({location}) => {

    return (
        <Layout className='page contactPage'>
            <SEO
                  title="LicenseSpring: Open Account"
                  description="Fill out our contact form and you will be put in touch with our customer success team who will help you start using your new LicenseSpring account."
                  path={location.pathname}
            />
            <div className="contactPage__background">
                <div className="container">
                    <section className='contactPage__intro'>
                        <h1 className='contactPage__title'>Open your LicenseSpring Account</h1>
                    </section>
                    <div className="row">
                        <Form />
                        <AllInOne />
                    </div>
                </div>
            </div>
            <TrustedBlock />
        </Layout >
    )
}
