import React from "react"
import { Link } from "gatsby"
import Tooltip from "../../../Tooltip"

import "./form.scss"

export default () => {
  const checkElement = async (selector) => {
    while (document.querySelector(selector) === null) {
      await new Promise((resolve) => requestAnimationFrame(resolve))
    }

    document.querySelectorAll(".fserv-select option")[2].selected = true
    return (document.querySelector(selector).innerHTML = "Standard")
  }

  React.useEffect(() => {
    const script = document.createElement("script")
    const container = document.getElementById("contactPage__formContainer")
    script.src =
      "https://licensespring.freshsales.io/web_forms/c736ccc0a06e86d9fd49d36ceb50ad98206209f0a4a71d65baf42762f41a623d/form.js"
    script.async = true
    script.crossorigin = "anonymous"
    script.id =
      "fs_c736ccc0a06e86d9fd49d36ceb50ad98206209f0a4a71d65baf42762f41a623d"
    container.appendChild(script)

    checkElement(".select2-selection__rendered")

    return () => {
      container.removeChild(script)
    }
  }, [])

  return (
    <div className="contactPage__form col__md-50">
      <p>
        Which Plan are you interested in?
        <Tooltip
          placement="bottom"
          content={
            <span>
              We offer three tiers of the LicenseSpring software licensing
              cloud. You can request access to our Free plan, or you can request
              a Trial for either our Standard or Enterprise plans.
              <br />
              <br />
              Please <Link to="/pricing/">view our pricing page</Link> for the
              difference between the accounts. Once our team verifies your
              contact information, you will receive account activation
              instructions via email.
            </span>
          }
        />
      </p>
      <div
        id="contactPage__formContainer"
        className="contactPage__formContainer"
        onChange={(e) => console.log(e)}
      ></div>
    </div>
  )
}
