import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Hand from '../../../../assets/img/hand.svg'
import Lock from '../../../../assets/img/lock.svg'
import Cloud from '../../../../assets/img/cloud.svg'
import Shield from '../../../../assets/img/shield.svg'

import './allinone.scss'

export default () =>
<StaticQuery
        query={graphql`
        query {
            ThirdParty: file(relativePath: { eq: "contact/thirdParty.png" }) {
                childImageSharp {
                    fluid(maxWidth: 1500) {
                    ...GatsbyImageSharpFluid
                    }
                }
            }
        }
        `}
        render={data => (
    <section className="contactPage__allInOne col__md-50">
        <div><img src={Hand} alt="Hand icon" /><p>Protect your Software IP</p></div>
        <div><img src={Lock} alt="Lock icon" /><p>Prevent unlicensed usage of your software</p></div>
        <div><img src={Shield} alt="Shield icon" /><p>Enforce your software licensing agreements with your end-users</p></div>
        <div><img src={Cloud} alt="Cloud icon" /><p> Cloud Based, works out of the box, fully scalable</p></div>
        <div className="contactPage__3rdParty">
            <h2>Seamless 3rd party integration</h2>
            <Img fluid={data.ThirdParty.childImageSharp.fluid} alt="3rdParty logos"/>
        </div>
    </section>
     )}
     />